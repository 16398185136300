export class Restaurant {
    constructor(public id: number,
                public name: string,
                public address: string,
                public phone: string){}
}

export class Employee {
    constructor(public id: number,
                public image: string,
                public firstName: string,
                public lastName: string,
                public middleName: string,
                public email: string,
                public phone: string,
                public addresses: Address[], 
                public position: Position){}
}

//['General Manager','Assistant Manager'] ... https://aptito.com/blog/restaurant-positions-and-descriptions
export class Position {
    constructor(public id: number,
                public name: string){}
}

export class Address {
    constructor(public id: number,
                public country: Country, 
                public city: string,
                public place: string,
                public postalCode: string,
                public addressLine: string){}
}

export class Country {
    constructor(public name: string,
                public code: string){}
}

export class Customer {
    constructor(public id: number,
                public fullName: string, 
                public email: string,
                public phoneNumber: string,
                public address: string){}
}

export class Reservation {
    constructor(public id: number,
                public date: string,
                public time: string,
                public customer: Customer,
                public guests: number,
                public tableNumber: number,
                public status: ReservationStatus){}
} 

// Approved, Cancelled, Pending
export class ReservationStatus {
    constructor(public id: number,
                public name: string){}
}
 

export class Order {
    constructor(public id: number,
                public date: string,
                public items: MenuItem[],
                public quantity: number,
                public amount: number,
                public status: OrderStatus){}
}  

//Completed, Processing, On Hold, Refunded, Pending
export class OrderStatus {
    constructor(public id: number,
                public name: string){}
}

// export class MenuItem {
//     constructor(public id: number,
//                 public name: string,
//                 public description: string,
//                 public price: number,
//                 public image: MenuItemImage,
//                 public discount: number,
//                 public ratingsCount: number,
//                 public ratingsValue: number,
//                 public availibilityCount: number,
//                 public cartCount: number,
//                 public weight: number,
//                 public isVegetarian: boolean,
//                 public categoryId: number){}
// }

export class MenuItem {
    constructor(public id: number,
                public name: string,
                public description: string,
                public price: number,
                public image: MenuItemImage,
                public discount: number,
                public ratingsCount: number,
                public ratingsValue: number,
                public availibilityCount: number,
                public cartCount: number,
                public weight: number,
                public isVegetarian: boolean,
                public categoryId: number){}
}

export class MenuItemImage {
    constructor(public small: string,
                public medium: string,
                public big: string){ }
}

export class Category {
    constructor(public id: number,
                public name: string,
                public description: string){ }
}   

export class Pagination {
    constructor(public page: number,
                public perPage: number,
                public prePage: number | null,
                public nextPage: number | null,
                public total: number,
                public totalPages: number){ }
}

export class Blog {
    constructor(public id: string,
                public title: string,
                public description: string,
                public fecha: Date,
                public imagen: string,
                public link_video: string){ }
}

export class Cotizacion {
    constructor(public usuario: string,
                public codsku: string,
                public price: number,
                public unidades: number,
                public fecha: Date,
                public email: string,
                public nameuser: string,
                public namesku: string){ }
}

export class Cotizaciones {
    constructor(public codcotiza: string,
                public name: string,
                public email: string,
                public unidades: number,
                public fecha: string,
                public status: string){ }
}

export class UserCotizacion {
    constructor(public id: number,
                public coduser: string,
                public codsku: string,
                public price: number,
                public unidades: number,
                public fecha: Date,
                public codstatus: string,
                public codcotiza: string
                ){ }
}

export class User {
    constructor(public coduser: string,
                public login: string,
                public password: string,
                public firstname: string,
                public lastname: string,
                public phone: string,
                public codstatus: string,
                public descstatus: string,
                public foto: string,
                public documento: string
                ){ }
}


export class Ejecutivo {
    constructor(public id: string,
                public nombre: string,
                public cargo: string,
                public facebook: string,
                public twitter: string,
                public linkedin: string,
                public instagram: string,
                public foto: string
                ){ }
}

export class InfoCv {
    constructor(public name: string,
                public email: string,
                public phone: string,
                public comment: string,
                public CvName: string
                ){ }
}
