<div class="section">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">Nuestros Servicios</h1> 
            <br><br>
            <div fxLayout="row wrap" class="services-wrapper">
                <div fxFlex="100" fxFlex.gt-sm="100" class="py-3"> 
                    <img src="assets/images/others/despacho-gratis.png" alt="about-1" style="width: 100%;">
                </div> 
                <!-- <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <mat-icon class="mat-icon-xlg" color="primary">fastfood</mat-icon>
                        <h2 class="secondary-font capitalize fw-600 my-3">Fresh Healthy Food</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum egestas nunc sed ultricies.</p>           
                    </mat-card> 
                </div>
                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <mat-icon class="mat-icon-xlg" color="primary">local_shipping</mat-icon>
                        <h2 class="secondary-font capitalize fw-600 my-3">Free Fast Home Delivery</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum egestas nunc sed ultricies.</p>             
                    </mat-card> 
                </div>

                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <mat-icon class="mat-icon-xlg" color="primary">local_offer</mat-icon>
                        <h2 class="secondary-font capitalize fw-600 my-3">Discount Voucher</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum egestas nunc sed ultricies.</p>             
                    </mat-card> 
                </div>

                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <mat-icon class="mat-icon-xlg" color="primary">more_time</mat-icon>
                        <h2 class="secondary-font capitalize fw-600 my-3">On Time Service</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum egestas nunc sed ultricies.</p>             
                    </mat-card> 
                </div>  -->

            </div>
                
        </div>
    </div>   
</div>