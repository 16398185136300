
<div class="logo"> 
  <!-- <img src="../../../assets/images/others/angular2.png" class="img-fluid" alt="Responsive image" style="height: 50px"> -->
  <img src="../../../assets/images/others/Sinoxsa-2-01.png" class="img-fluid mt-1" alt="Responsive image" style="height: 60px;">
</div>




 



