<div class="section mt-3">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title mb-3">Our Awards</h1>
            <p class="section-desc text-muted">Mox restaurant is the best restourant in the country and been recognized with many awards. We promise to provide excellent service to you forever for happiness and success. For getting more details please visit us.</p>
  
            <div class="awards-carousel"> 
                <div class="swiper-container h-100" [swiper]="config"> 
                    <div class="swiper-wrapper h-100">      
                        <div *ngFor="let award of awards" class="swiper-slide">
                            <div class="award-item"> 
                                <img [attr.data-src]="award.image" class="swiper-lazy"> 
                                <div class="swiper-lazy-preloader"></div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
                
        </div>
    </div>   
  </div>