<div class="px-3 pt-5">
    <div class="theme-container pt-5">  
        <div class="get-in-touch bg-primary" ngClass.lt-md="mt-3 pt-5"> 
            <img src="assets/images/others/looking_transp.png" alt="operator" fxShow="false" fxShow.gt-xs> 
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="content" ngClass.xs="p-0"> 
                <div fxLayout="column" class="py-2 px-3">
                    <h1 class="secondary-font">¿Busca algún producto?</h1>
                    <!-- <p class="mb-0">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p> -->
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="py-2 px-3">
                    <mat-icon class="mat-icon-xlg mx-2">call</mat-icon>
                    <div fxLayout="column">               
                        <p class="mb-0">Telefonos</p>
                        <h2 class="ws-nowrap">424-405.49.68</h2>
                        <h2 class="ws-nowrap">414-417.26.81</h2>
                    </div>
                </div>
                <div class="py-2 px-3">
                    <a mat-flat-button routerLink="/contact" class="mx-3 uppercase">Contactanos</a>
                </div>
            </div>  
        </div> 
    </div>
</div>