import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, Order, Category, Blog, Cotizaciones, Cotizacion, UserCotizacion, User, Ejecutivo, InfoCv } from 'src/app/app.models'; 
import { AppSettings } from 'src/app/app.settings'; 
import { environment } from 'src/environments/environment';   
import { ConfirmDialogComponent, ConfirmDialogModel } from './shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {Md5} from "md5-typescript";

//const base_url="http://localhost:60638/api";
const base_url="https://sinoxsaapi.sinoxsa.com/api";

export class Data {
  constructor(public categories: Category[], 
              public cartList: MenuItem[],
              public orderList: Order[],
              public favorites: MenuItem[], 
              public totalPrice: number,
              public totalCartCount: number
              ) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public Data = new Data(
    [],  // categories 
    [],  // cartList
    [],  // orderList
    [],  // favorites 
    0, // totalPrice
    0 //totalCartCount
  )  
  
  public url = environment.url + '/assets/data/'; 
  
  constructor(public http:HttpClient, 
              private datePipe:DatePipe,
              private bottomSheet: MatBottomSheet, 
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              public appSettings:AppSettings,
              public translateService: TranslateService,
              private router:Router) { }  
  
 /************************************************************************************************/
 /* Inicio Metodos de sinoxsa */
 /************************************************************************************************/
  get token(): string {
    return localStorage.getItem('tkn') || '';
  }
      
  public get user(): any {
    return localStorage.getItem('user') || '';
  }

  login(formData: any){
    return this.http.post(`${base_url}/login/authenticate`, formData)
      .pipe(
        tap((resp:any)=>{
          localStorage.setItem('tkn', resp.idtkn);
        })
      );
  }

  validarToken(): boolean{
    const token = localStorage.getItem('tkn') || '';

    if(token==''){
      return false;
    }

    return true;
  }

  logOut(){
    localStorage.clear();
    this.router.navigateByUrl('');
  }

  public registrarUser(datos:any) {
    var user:any={
      "coduser": "00000000-0000-0000-0000-000000000000",
      "login": datos.username,
      "password":   Md5.init(datos.password),
      "email": datos.email,
      "firstname": "",
      "lastname": "",
      "phone": datos.telefono,
      "documento": datos.documento
    };

    return this.http.post<string>(`${base_url}/public/register/`, user);
  }

  public updateUser(datos:any) {
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    var user:any={
      "coduser": datos.coduser,
      "login": datos.login,
      "password": "123456789",
      "email": datos.email,
      "firstname": datos.firstname,
      "lastname": datos.lastname,
      "phone": datos.phone,
      "codstatus":  datos.codstatus,
      "foto": "",
      "documento": datos.documento
    };

    return this.http.put<string>(`${base_url}/user/`, user, { headers });
  }

  public blockUser(coduser:string,codstatus:string) {
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    var blockUser:any={
      "coduser": coduser,
      "codstatus": codstatus
    };

    return this.http.put<string>(`${base_url}/user/bloquear/` , blockUser, { headers });
  }

  public getUsers(): Observable<User[]>{
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    return this.http.get<User[]>(`${base_url}/user/`, { headers });
  }

  public getCategories(): Observable<Category[]>{ 
    return this.http.get<Category[]>(`${base_url}/public/categorias`);
  }  

  public updateCategories(datos: Category, agregar:number): Observable<string>{
    // var coduser:string=JSON.parse(this.user).coduser;
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');
 
    if (agregar==1){
      return this.http.post<string>(`${base_url}/categorias/`, datos, { headers });
    }
    else{
      return this.http.post<string>(`${base_url}/categorias/`, datos, { headers });
    }
  }

  public deleteCategories(id:string): Observable<string>{
    // var coduser:string=JSON.parse(this.user).coduser;
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');
 
    return this.http.delete<string>(`${base_url}/categorias/` + id, { headers });
  }

  public getMenuItems(): Observable<MenuItem[]>{
    return this.http.get<MenuItem[]>(`${base_url}/public/getallproducts`);
  } 

  public updateSku(datos: MenuItem, agregar:number): Observable<string>{
    // var coduser:string=JSON.parse(this.user).coduser;
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    if (agregar==1){
      return this.http.post<string>(`${base_url}/sku/`, datos, { headers });
    }
    else{
      return this.http.put<string>(`${base_url}/sku/`, datos, { headers });
    }
  }

  public deleteSku(id:string): Observable<string>{
    // var coduser:string=JSON.parse(this.user).coduser;
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');
 
    return this.http.delete<string>(`${base_url}/sku/` + id, { headers });
  }

  public getSkuById(id:string): Observable<MenuItem>{
    return this.http.get<MenuItem>(`${base_url}/public/getproductbyid/` + id);
  }

  public getMenuItemById(id:number): Observable<MenuItem>{
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    return this.http.get<MenuItem>(`${base_url}/sku/` + id, { headers });
  }

  async actualizarFoto(archivo: File) {

    try {

      const url = `${ base_url }/uploadimage`;
      const formData = new FormData();
      formData.append('imagen', archivo);

      const resp = await fetch( url, {
        method: 'POST',
        headers: {
          'x-token': localStorage.getItem('token') || ''
        },
        body: formData
      });

      const data = await resp.json();

      if ( data.ok ) {
        return data.nombreArchivo;
      } else {
        console.log(data.msg);
        return false;
      }
      
    } catch (error) {
      console.log(error);
      return false;    
    }

  }

  async subirCV(archivo: File) {

    try {

      const url = `${ base_url }/public/uploadcv`;
      const formData = new FormData();
      formData.append('imagen', archivo);

      const resp = await fetch( url, {
        method: 'POST',
        headers: {
          'x-token': localStorage.getItem('token') || ''
        },
        body: formData
      });

      const data = await resp.json();

      if ( data.ok ) {
        return data.nombreArchivo;
      } else {
        console.log(data.msg);
        return false;
      }
      
    } catch (error) {
      console.log(error);
      return false;    
    }

  }

  public getSpecialMenuItems(): Observable<MenuItem[]>{
    return this.http.get<MenuItem[]>(`${base_url}/public/top5product`);
  } 

  public getBlogs(): Observable<Blog[]>{ 
    return this.http.get<Blog[]>(`${base_url}/public/blogs`);
  }  

  public updateBlog(datos: Blog, agregar:number): Observable<string>{
    // var coduser:string=JSON.parse(this.user).coduser;
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');
 
    if (agregar==1){
      return this.http.post<string>(`${base_url}/blogs/`, datos, { headers });
    }
    else{
      return this.http.put<string>(`${base_url}/blogs/`, datos, { headers });
    }
  }

  public getBlogById(id:string): Observable<Blog>{
    return this.http.get<Blog>(`${base_url}/public/blogbyid/` + id.trim());
  }

  public deleteBlog(id:string): Observable<string>{
    // var coduser:string=JSON.parse(this.user).coduser;
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');
 
    return this.http.delete<string>(`${base_url}/blogs/` + id, { headers });
  }

  public getCotizaciones(): Observable<Cotizaciones[]>{ 
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    return this.http.get<Cotizaciones[]>(`${base_url}/cotizaciones`, { headers });
  }  

  public getCotizacionUser(id:string): Observable<Cotizacion[]>{ 
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    return this.http.get<Cotizacion[]>(`${base_url}/cotizaciones/getbyuser/` + id, { headers });
  }  

  public updateCotizaciones(lcotizaciones: UserCotizacion[]): Observable<string>{
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    return this.http.post<string>(`${base_url}/cotizaciones/`, lcotizaciones, { headers });
  }

  public aprobar(codcotiza: string ): Observable<string>{
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    return this.http.get<string>(`${base_url}/cotizaciones/aprobar/` + codcotiza, { headers });
  }

  public getEjecutivos(): Observable<Ejecutivo[]>{ 
    return this.http.get<Ejecutivo[]>(`${base_url}/public/ejecutivos`);
  }  

  public getEjecutivoById(id:number): Observable<Ejecutivo>{
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    return this.http.get<Ejecutivo>(`${base_url}/ejecutivo/` + id, { headers });
  }

  public updateEjecutivo(datos: Ejecutivo): Observable<string>{
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');

    return this.http.post<string>(`${base_url}/ejecutivo/`, datos, { headers });
  }

  public deleteEjecutivo(id:string): Observable<string>{
    // var coduser:string=JSON.parse(this.user).coduser;
    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.token)
    .set('Content-Type', 'application/json');
 
    return this.http.delete<string>(`${base_url}/ejecutivo/` + id, { headers });
  }

  public EnviarCv(objCv:InfoCv): Observable<string>{   
    return this.http.post<string>(`${base_url}/public/sendcv` , objCv);
  }
 /************************************************************************************************/
 /* Fin Metodos de sinoxsa */
 /************************************************************************************************/

  // public getMenuItems(): Observable<MenuItem[]>{
  //   return this.http.get<MenuItem[]>(this.url + 'menu-items.json');
  // } 
 
  // public getMenuItemById(id:number): Observable<MenuItem>{
  //   return this.http.get<MenuItem>(this.url + 'menu-item-' + id + '.json');
  // }
 
  // public getSpecialMenuItems(): Observable<MenuItem[]>{
  //   return this.http.get<MenuItem[]>(this.url + 'special-menu-items.json');
  // } 

  public getBestMenuItems(): Observable<MenuItem[]>{
    return this.http.get<MenuItem[]>(this.url + 'best-menu-items.json');
  } 

  // public getCategories(): Observable<Category[]>{
  //   return this.http.get<Category[]>(this.url + 'categories.json');
  // }  

  public getHomeCarouselSlides(){
    return this.http.get<any[]>(this.url + 'slides.json');
  }

  public getReservations(){
    return this.http.get<any[]>(this.url + 'reservations.json');
  }

  public getOrders(){
    return this.http.get<any[]>(this.url + 'orders.json');
  }

  public getGUID(){
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    }).toLowerCase();
    return guid;
  }

  public addToCart(menuItem:MenuItem, component:any){   
    if(!this.Data.cartList.find(item=>item.id == menuItem.id)){
      menuItem.cartCount = (menuItem.cartCount) ? menuItem.cartCount : 1;
      this.Data.cartList.push(menuItem); 
      this.calculateCartTotal(); 
      if(component){
        this.openCart(component);        
      }
      else{ 
        this.snackBar.open('El producto "' + menuItem.name + '" ha sido agregado a la cotización.', '×', {
          verticalPosition: 'top',
          duration: 3000,
          direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
          panelClass: ['success'] 
        });
      }
    }  
  } 

  public openCart(component:any){  
    this.bottomSheet.open(component, {
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr'
    }).afterDismissed().subscribe(isRedirect=>{  
      if(isRedirect){ 
        window.scrollTo(0,0);  
      }        
    });  
  }

  public calculateCartTotal(){
    this.Data.totalPrice = 0;
    this.Data.totalCartCount = 0;
    this.Data.cartList.forEach(item=>{
      let price = 0;
      if(item.discount){
        price = item.price - (item.price * (item.discount / 100));
      }
      else{
        price = item.price;
      }
      this.Data.totalPrice = this.Data.totalPrice + (price * item.cartCount); 
      this.Data.totalCartCount = this.Data.totalCartCount + item.cartCount;  
    });
  }

  public addToFavorites(menuItem:MenuItem){
    let message:string, status:string;
    if(this.Data.favorites.find(item=>item.id == menuItem.id)){ 
      message = 'The menu item "' + menuItem.name + '" already added to favorites.'; 
      status = 'error';    
    } 
    else{
      this.Data.favorites.push(menuItem);
      message = 'The menu item "' + menuItem.name + '" has been added to favorites.'; 
      status = 'success';  
    } 
    this.snackBar.open(message, '×', {
      verticalPosition: 'top',
      duration: 3000,
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
      panelClass: [status] 
    });   
  }

  public openDialog(component:any, data:any, panelClass:any){ 
    return this.dialog.open(component, {
      data: data, 
      panelClass: panelClass,
      autoFocus: false,
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr'
    });  
  }

  public openConfirmDialog(title:string, message:string) {  
    const dialogData = new ConfirmDialogModel(title, message); 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    }); 
    return dialogRef; 
  }

  public openAlertDialog(message:string) {   
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data: message
    }); 
    return dialogRef; 
  }

  public makeReservation(dialogComponent:any, data:any, onDialog:boolean = false){
    if(onDialog){
      const dialogRef = this.openDialog(dialogComponent, null, 'theme-dialog');
      dialogRef.afterClosed().subscribe(data => {  
        if(data){ 
          this.showReservationMessage(data);
        } 
      }); 
    }
    else{
      this.showReservationMessage(data);
    } 
  }
  private showReservationMessage(data:any){
    this.snackBar.open('Dear ' + data.fullName + ', thank you for your reservation! Your reservation at Popino on the '+ this.datePipe.transform( data.date,'dd-MM-yyyy') +' at '+ data.time +' for '+ data.guests +' people will review by our team and someone will be in touch soon.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 9000 });
  } 

  public getTranslateValue(key:string, param:string = ''){  
    let value = null;
    this.translateService.get(key, { param: param }).subscribe((res: string) => {
      value = res;
    }) 
    return value; 
  } 

  public filterData(data:any, categoryId:number, sort?:string, page?:number, perPage?:number){  
    if(categoryId){
      data = data.filter((item:any) => item.categoryId == categoryId);
    }   

    //for show more properties mock data 
    // for (var index = 0; index < 2; index++) {
    //   data = data.concat(data);        
    // }     
     
    this.sortData(sort, data);
    return this.paginator(data, page, perPage)
  }

  public sortData(sort?:string, data?:any){
    if(sort){
      switch (sort) { 
        case 'Popular':
          data = data.sort((a:any, b:any) => { 
            if(a.ratingsValue/a.ratingsCount < b.ratingsValue/b.ratingsCount){
              return 1;
            }
            if(a.ratingsValue/a.ratingsCount > b.ratingsValue/b.ratingsCount){
              return -1;
            }
            return 0; 
          });
          break;
        case 'Price (Low to High)': 
          data = data.sort((a:any,b:any) => {
            if(a.price > b.price){
              return 1;
            }
            if(a.price < b.price){
              return -1;
            }
            return 0;  
          });
          break;
        case 'Price (High to Low)': 
          data = data.sort((a:any,b:any) => {
            if(a.price < b.price){
              return 1;
            }
            if(a.price > b.price){
              return -1;
            }
            return 0;  
          }); 
          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items:any, page?:any, perPage?:any) { 
    var page = page || 1,
    perPage = perPage || 4,
    offset = (page - 1) * perPage,   
    paginatedItems = items.slice(offset).slice(0, perPage),
    totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination:{
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      }
    };
  }  

  public getCountries(){
    return [ 
        {name: 'Afghanistan', code: 'AF'}, 
        {name: 'Aland Islands', code: 'AX'}, 
        {name: 'Albania', code: 'AL'}, 
        {name: 'Algeria', code: 'DZ'}, 
        {name: 'American Samoa', code: 'AS'}, 
        {name: 'AndorrA', code: 'AD'}, 
        {name: 'Angola', code: 'AO'}, 
        {name: 'Anguilla', code: 'AI'}, 
        {name: 'Antarctica', code: 'AQ'}, 
        {name: 'Antigua and Barbuda', code: 'AG'}, 
        {name: 'Argentina', code: 'AR'}, 
        {name: 'Armenia', code: 'AM'}, 
        {name: 'Aruba', code: 'AW'}, 
        {name: 'Australia', code: 'AU'}, 
        {name: 'Austria', code: 'AT'}, 
        {name: 'Azerbaijan', code: 'AZ'}, 
        {name: 'Bahamas', code: 'BS'}, 
        {name: 'Bahrain', code: 'BH'}, 
        {name: 'Bangladesh', code: 'BD'}, 
        {name: 'Barbados', code: 'BB'}, 
        {name: 'Belarus', code: 'BY'}, 
        {name: 'Belgium', code: 'BE'}, 
        {name: 'Belize', code: 'BZ'}, 
        {name: 'Benin', code: 'BJ'}, 
        {name: 'Bermuda', code: 'BM'}, 
        {name: 'Bhutan', code: 'BT'}, 
        {name: 'Bolivia', code: 'BO'}, 
        {name: 'Bosnia and Herzegovina', code: 'BA'}, 
        {name: 'Botswana', code: 'BW'}, 
        {name: 'Bouvet Island', code: 'BV'}, 
        {name: 'Brazil', code: 'BR'}, 
        {name: 'British Indian Ocean Territory', code: 'IO'}, 
        {name: 'Brunei Darussalam', code: 'BN'}, 
        {name: 'Bulgaria', code: 'BG'}, 
        {name: 'Burkina Faso', code: 'BF'}, 
        {name: 'Burundi', code: 'BI'}, 
        {name: 'Cambodia', code: 'KH'}, 
        {name: 'Cameroon', code: 'CM'}, 
        {name: 'Canada', code: 'CA'}, 
        {name: 'Cape Verde', code: 'CV'}, 
        {name: 'Cayman Islands', code: 'KY'}, 
        {name: 'Central African Republic', code: 'CF'}, 
        {name: 'Chad', code: 'TD'}, 
        {name: 'Chile', code: 'CL'}, 
        {name: 'China', code: 'CN'}, 
        {name: 'Christmas Island', code: 'CX'}, 
        {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
        {name: 'Colombia', code: 'CO'}, 
        {name: 'Comoros', code: 'KM'}, 
        {name: 'Congo', code: 'CG'}, 
        {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
        {name: 'Cook Islands', code: 'CK'}, 
        {name: 'Costa Rica', code: 'CR'}, 
        {name: 'Cote D\'Ivoire', code: 'CI'}, 
        {name: 'Croatia', code: 'HR'}, 
        {name: 'Cuba', code: 'CU'}, 
        {name: 'Cyprus', code: 'CY'}, 
        {name: 'Czech Republic', code: 'CZ'}, 
        {name: 'Denmark', code: 'DK'}, 
        {name: 'Djibouti', code: 'DJ'}, 
        {name: 'Dominica', code: 'DM'}, 
        {name: 'Dominican Republic', code: 'DO'}, 
        {name: 'Ecuador', code: 'EC'}, 
        {name: 'Egypt', code: 'EG'}, 
        {name: 'El Salvador', code: 'SV'}, 
        {name: 'Equatorial Guinea', code: 'GQ'}, 
        {name: 'Eritrea', code: 'ER'}, 
        {name: 'Estonia', code: 'EE'}, 
        {name: 'Ethiopia', code: 'ET'}, 
        {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
        {name: 'Faroe Islands', code: 'FO'}, 
        {name: 'Fiji', code: 'FJ'}, 
        {name: 'Finland', code: 'FI'}, 
        {name: 'France', code: 'FR'}, 
        {name: 'French Guiana', code: 'GF'}, 
        {name: 'French Polynesia', code: 'PF'}, 
        {name: 'French Southern Territories', code: 'TF'}, 
        {name: 'Gabon', code: 'GA'}, 
        {name: 'Gambia', code: 'GM'}, 
        {name: 'Georgia', code: 'GE'}, 
        {name: 'Germany', code: 'DE'}, 
        {name: 'Ghana', code: 'GH'}, 
        {name: 'Gibraltar', code: 'GI'}, 
        {name: 'Greece', code: 'GR'}, 
        {name: 'Greenland', code: 'GL'}, 
        {name: 'Grenada', code: 'GD'}, 
        {name: 'Guadeloupe', code: 'GP'}, 
        {name: 'Guam', code: 'GU'}, 
        {name: 'Guatemala', code: 'GT'}, 
        {name: 'Guernsey', code: 'GG'}, 
        {name: 'Guinea', code: 'GN'}, 
        {name: 'Guinea-Bissau', code: 'GW'}, 
        {name: 'Guyana', code: 'GY'}, 
        {name: 'Haiti', code: 'HT'}, 
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
        {name: 'Holy See (Vatican City State)', code: 'VA'}, 
        {name: 'Honduras', code: 'HN'}, 
        {name: 'Hong Kong', code: 'HK'}, 
        {name: 'Hungary', code: 'HU'}, 
        {name: 'Iceland', code: 'IS'}, 
        {name: 'India', code: 'IN'}, 
        {name: 'Indonesia', code: 'ID'}, 
        {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
        {name: 'Iraq', code: 'IQ'}, 
        {name: 'Ireland', code: 'IE'}, 
        {name: 'Isle of Man', code: 'IM'}, 
        {name: 'Israel', code: 'IL'}, 
        {name: 'Italy', code: 'IT'}, 
        {name: 'Jamaica', code: 'JM'}, 
        {name: 'Japan', code: 'JP'}, 
        {name: 'Jersey', code: 'JE'}, 
        {name: 'Jordan', code: 'JO'}, 
        {name: 'Kazakhstan', code: 'KZ'}, 
        {name: 'Kenya', code: 'KE'}, 
        {name: 'Kiribati', code: 'KI'}, 
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
        {name: 'Korea, Republic of', code: 'KR'}, 
        {name: 'Kuwait', code: 'KW'}, 
        {name: 'Kyrgyzstan', code: 'KG'}, 
        {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
        {name: 'Latvia', code: 'LV'}, 
        {name: 'Lebanon', code: 'LB'}, 
        {name: 'Lesotho', code: 'LS'}, 
        {name: 'Liberia', code: 'LR'}, 
        {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
        {name: 'Liechtenstein', code: 'LI'}, 
        {name: 'Lithuania', code: 'LT'}, 
        {name: 'Luxembourg', code: 'LU'}, 
        {name: 'Macao', code: 'MO'}, 
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
        {name: 'Madagascar', code: 'MG'}, 
        {name: 'Malawi', code: 'MW'}, 
        {name: 'Malaysia', code: 'MY'}, 
        {name: 'Maldives', code: 'MV'}, 
        {name: 'Mali', code: 'ML'}, 
        {name: 'Malta', code: 'MT'}, 
        {name: 'Marshall Islands', code: 'MH'}, 
        {name: 'Martinique', code: 'MQ'}, 
        {name: 'Mauritania', code: 'MR'}, 
        {name: 'Mauritius', code: 'MU'}, 
        {name: 'Mayotte', code: 'YT'}, 
        {name: 'Mexico', code: 'MX'}, 
        {name: 'Micronesia, Federated States of', code: 'FM'}, 
        {name: 'Moldova, Republic of', code: 'MD'}, 
        {name: 'Monaco', code: 'MC'}, 
        {name: 'Mongolia', code: 'MN'}, 
        {name: 'Montserrat', code: 'MS'}, 
        {name: 'Morocco', code: 'MA'}, 
        {name: 'Mozambique', code: 'MZ'}, 
        {name: 'Myanmar', code: 'MM'}, 
        {name: 'Namibia', code: 'NA'}, 
        {name: 'Nauru', code: 'NR'}, 
        {name: 'Nepal', code: 'NP'}, 
        {name: 'Netherlands', code: 'NL'}, 
        {name: 'Netherlands Antilles', code: 'AN'}, 
        {name: 'New Caledonia', code: 'NC'}, 
        {name: 'New Zealand', code: 'NZ'}, 
        {name: 'Nicaragua', code: 'NI'}, 
        {name: 'Niger', code: 'NE'}, 
        {name: 'Nigeria', code: 'NG'}, 
        {name: 'Niue', code: 'NU'}, 
        {name: 'Norfolk Island', code: 'NF'}, 
        {name: 'Northern Mariana Islands', code: 'MP'}, 
        {name: 'Norway', code: 'NO'}, 
        {name: 'Oman', code: 'OM'}, 
        {name: 'Pakistan', code: 'PK'}, 
        {name: 'Palau', code: 'PW'}, 
        {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
        {name: 'Panama', code: 'PA'}, 
        {name: 'Papua New Guinea', code: 'PG'}, 
        {name: 'Paraguay', code: 'PY'}, 
        {name: 'Peru', code: 'PE'}, 
        {name: 'Philippines', code: 'PH'}, 
        {name: 'Pitcairn', code: 'PN'}, 
        {name: 'Poland', code: 'PL'}, 
        {name: 'Portugal', code: 'PT'}, 
        {name: 'Puerto Rico', code: 'PR'}, 
        {name: 'Qatar', code: 'QA'}, 
        {name: 'Reunion', code: 'RE'}, 
        {name: 'Romania', code: 'RO'}, 
        {name: 'Russian Federation', code: 'RU'}, 
        {name: 'RWANDA', code: 'RW'}, 
        {name: 'Saint Helena', code: 'SH'}, 
        {name: 'Saint Kitts and Nevis', code: 'KN'}, 
        {name: 'Saint Lucia', code: 'LC'}, 
        {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
        {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
        {name: 'Samoa', code: 'WS'}, 
        {name: 'San Marino', code: 'SM'}, 
        {name: 'Sao Tome and Principe', code: 'ST'}, 
        {name: 'Saudi Arabia', code: 'SA'}, 
        {name: 'Senegal', code: 'SN'}, 
        {name: 'Serbia and Montenegro', code: 'CS'}, 
        {name: 'Seychelles', code: 'SC'}, 
        {name: 'Sierra Leone', code: 'SL'}, 
        {name: 'Singapore', code: 'SG'}, 
        {name: 'Slovakia', code: 'SK'}, 
        {name: 'Slovenia', code: 'SI'}, 
        {name: 'Solomon Islands', code: 'SB'}, 
        {name: 'Somalia', code: 'SO'}, 
        {name: 'South Africa', code: 'ZA'}, 
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
        {name: 'Spain', code: 'ES'}, 
        {name: 'Sri Lanka', code: 'LK'}, 
        {name: 'Sudan', code: 'SD'}, 
        {name: 'Suriname', code: 'SR'}, 
        {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
        {name: 'Swaziland', code: 'SZ'}, 
        {name: 'Sweden', code: 'SE'}, 
        {name: 'Switzerland', code: 'CH'}, 
        {name: 'Syrian Arab Republic', code: 'SY'}, 
        {name: 'Taiwan, Province of China', code: 'TW'}, 
        {name: 'Tajikistan', code: 'TJ'}, 
        {name: 'Tanzania, United Republic of', code: 'TZ'}, 
        {name: 'Thailand', code: 'TH'}, 
        {name: 'Timor-Leste', code: 'TL'}, 
        {name: 'Togo', code: 'TG'}, 
        {name: 'Tokelau', code: 'TK'}, 
        {name: 'Tonga', code: 'TO'}, 
        {name: 'Trinidad and Tobago', code: 'TT'}, 
        {name: 'Tunisia', code: 'TN'}, 
        {name: 'Turkey', code: 'TR'}, 
        {name: 'Turkmenistan', code: 'TM'}, 
        {name: 'Turks and Caicos Islands', code: 'TC'}, 
        {name: 'Tuvalu', code: 'TV'}, 
        {name: 'Uganda', code: 'UG'}, 
        {name: 'Ukraine', code: 'UA'}, 
        {name: 'United Arab Emirates', code: 'AE'}, 
        {name: 'United Kingdom', code: 'GB'}, 
        {name: 'United States', code: 'US'}, 
        {name: 'United States Minor Outlying Islands', code: 'UM'}, 
        {name: 'Uruguay', code: 'UY'}, 
        {name: 'Uzbekistan', code: 'UZ'}, 
        {name: 'Vanuatu', code: 'VU'}, 
        {name: 'Venezuela', code: 'VE'}, 
        {name: 'Viet Nam', code: 'VN'}, 
        {name: 'Virgin Islands, British', code: 'VG'}, 
        {name: 'Virgin Islands, U.S.', code: 'VI'}, 
        {name: 'Wallis and Futuna', code: 'WF'}, 
        {name: 'Western Sahara', code: 'EH'}, 
        {name: 'Yemen', code: 'YE'}, 
        {name: 'Zambia', code: 'ZM'}, 
        {name: 'Zimbabwe', code: 'ZW'} 
    ]
  } 

  public getTestimonials(){
    return [
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Mr. Adam Sandler', 
            position: 'General Director', 
            image: 'assets/images/profile/adam.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Ashley Ahlberg', 
            position: 'Housewife', 
            image: 'assets/images/profile/ashley.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Bruno Vespa', 
            position: 'Blogger', 
            image: 'assets/images/profile/bruno.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Mrs. Julia Aniston', 
            position: 'Marketing Manager', 
            image: 'assets/images/profile/julia.jpg' 
        }
    ];
  }

  public getChefs(){
    return [ 
          { 
            id: 1,
            fullName: 'Andy Warhol',
            position: 'Director',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'andy.w@mox.com',
            phone: '(212) 457-2308',
            social: {
              facebook: 'andy.warhol',
              twitter: 'andy.warhol',
              linkedin: 'andy.warhol',
              instagram: 'andy.warhol',
              website: 'https://andy.warhol.com'
            },
            ratingsCount: 4,
            ratingsValue: 400,
            image: 'assets/images/others/man.jpg' 
        },        
        { 
            id: 2,
            fullName: 'Lusia Manuel',
            position: 'Asistente al Director',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'lusia.m@mox.com',
            phone: '(224) 267-1346',
            social: {
              facebook: 'lusia.manuel',
              twitter: 'lusia.manuel',
              linkedin: 'lusia.manuel',
              instagram: 'lusia.manuel',
              website: 'https://lusia.manuel.com'
            },
            ratingsCount: 6,
            ratingsValue: 480,
            image: 'assets/images/others/woman.jpg' 
        },
        { 
            id: 3,
            fullName: 'Michael Blair',
            position: 'Contador',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'michael.b@mox.com',
            phone: '(267) 388-1637',
            social: {
              facebook: 'michael.blair',
              twitter: 'michael.blair',
              linkedin: 'michael.blair',
              instagram: 'michael.blair',
              website: 'https://michael.blair.com'
            },
            ratingsCount: 4,
            ratingsValue: 400,
            image: 'assets/images/others/man.jpg' 
        },        
        { 
            id: 4,
            fullName: 'Tereza Stiles',
            position: 'Administrador',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'tereza.s@mox.com',
            phone: '(214) 617-2614',
            social: {
              facebook: 'tereza.stiles',
              twitter: 'tereza.stiles',
              linkedin: 'tereza.stiles',
              instagram: 'tereza.stiles',
              website: 'https://tereza.stiles.com'
            },
            ratingsCount: 4,
            ratingsValue: 380,
            image: 'assets/images/others/woman.jpg' 
        }, 
        { 
            id: 5,
            fullName: 'Michelle Ormond',
            position: 'Asitente Administrativo',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'michelle.o@mox.com',
            phone: '(267) 388-1637',
            social: {
              facebook: 'michelle.ormond',
              twitter: 'michelle.ormond',
              linkedin: 'michelle.ormond',
              instagram: 'michelle.ormond',
              website: 'https://michelle.ormond.com'
            },
            ratingsCount: 6,
            ratingsValue: 480, 
            image: 'assets/images/others/woman.jpg' 
        }
    ];
  } 

  public getAwards(){
    return [  
        { name: 'award-1', image: 'assets/images/awards/1.png' },
        { name: 'award-2', image: 'assets/images/awards/2.png' },
        { name: 'award-3', image: 'assets/images/awards/3.png' },
        { name: 'award-4', image: 'assets/images/awards/4.png' },
        { name: 'award-5', image: 'assets/images/awards/5.png' },
        { name: 'award-6', image: 'assets/images/awards/6.png' },
        { name: 'award-7', image: 'assets/images/awards/7.png' } 
    ];
  }

  public getDeliveryMethods(){
    return [
        { value: 'free', name: 'Free Delivery', desc: '$0.00 / Delivery in 7 to 14 business Days' },
        { value: 'standard', name: 'Standard Delivery', desc: '$7.99 / Delivery in 5 to 7 business Days' },
        { value: 'express', name: 'Express Delivery', desc: '$29.99 / Delivery in 1 business Days' }
    ]
  }

  public getMonths(){
    return [
      { value: '01', name: 'January' },
      { value: '02', name: 'February' },
      { value: '03', name: 'March' },
      { value: '04', name: 'April' },
      { value: '05', name: 'May' },
      { value: '06', name: 'June' },
      { value: '07', name: 'July' },
      { value: '08', name: 'August' },
      { value: '09', name: 'September' },
      { value: '10', name: 'October' },
      { value: '11', name: 'November' },
      { value: '12', name: 'December' }
    ]
  }

  public getYears(){
    const startYear = new Date().getFullYear();
    let years = Array();  
    for (let i = 0; i <= 10; i++) { 
      years.push(startYear + i);      
    }   
    return years; 
  } 

  public shuffleArray(array:any){
    var currentIndex = array.length, temporaryValue, randomIndex; 
    while (0 !== currentIndex) { 
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1; 
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    } 
    return array;
  } 

  public convertImgToBase64(url:string, callback:any){
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  
  private mailApi = 'https://mailthis.to/codeninja'
  public PostMessage(input: any) {
    return this.http.post(this.mailApi, input, { responseType: 'text' })
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  } 

  // public getBlogs(){
  //   return [ 
  //         { 
  //           id: "0001",
  //           title: 'Lorem Ipsum is simply dummy text of the printing',
  //           desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
  //           fecha: '01/10/2022',
  //           image: 'assets/images/others/blog1.jpg' 
  //         },        
  //         { 
  //           id: "0002",
  //           title: 'Lorem Ipsum is simply dummy text of the printing',
  //           desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
  //           fecha: '10/10/2022',
  //           image: 'assets/images/others/blog1.jpg' 
  //         },  
  //         { 
  //           id: "0003",
  //           title: 'Lorem Ipsum is simply dummy text of the printing',
  //           desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
  //           fecha: '12/10/2022',
  //           image: 'assets/images/others/blog1.jpg' 
  //         },
  //         { 
  //           id: "0004",
  //           title: 'Lorem Ipsum is simply dummy text of the printing',
  //           desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
  //           fecha: '01/11/2022',
  //           image: 'assets/images/others/blog1.jpg' 
  //         }    
  //   ];
  // } 

  public getBlog(){
    return [ 
          { 
            id: "0001",
            title: 'Lorem Ipsum is simply dummy text of the printing',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            fecha: '01/10/2022',
            image: 'blog1.jpg' 
          },
          { 
            id: "0002",
            title: 'Lorem Ipsum is simply dummy text of the printing',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            fecha: '01/10/2022',
            image: 'blog1.jpg' 
          },
          { 
            id: "0003",
            title: 'Lorem Ipsum is simply dummy text of the printing',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            fecha: '01/10/2022',
            image: 'blog1.jpg' 
          },
          { 
            id: "0004",
            title: 'Lorem Ipsum is simply dummy text of the printing',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            fecha: '01/10/2022',
            image: 'blog1.jpg' 
          }
        ];
  }         

}
