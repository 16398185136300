<div class="section chefs">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title mb-3">Nuestros Ejecutivos</h1>
            <br><br>
            <!-- <p class="section-desc text-muted">Lorem ipsum dolor sit amet.</p> -->

            <div class="chefs-carousel"> 
                <div class="swiper-container h-100 carousel-outer" [swiper]="config"> 
                    <div class="swiper-wrapper h-100">      
                        <div *ngFor="let ejecutivo of ejecutivos" class="swiper-slide"> 
                            <mat-card>
                                <!-- <img mat-card-image [attr.data-src]="ejecutivo.foto" alt="" class="swiper-lazy d-block"> -->
                                <img src="https://sinoxsaapi.sinoxsa.com/api/public/getimage?fileName={{ ejecutivo.foto }}" [alt]="ejecutivo.foto" class="swiper-lazy d-block max-img">
                                <!-- <img src="http://localhost:60638/api/public/getimage?fileName={{ ejecutivo.foto }}" [alt]="ejecutivo.foto" class="swiper-lazy d-block"> -->
                                <!-- <div class="swiper-lazy-preloader"></div> -->
                                <mat-card-content class="text-center">
                                    <h2 class="secondary-font fw-600">{{ejecutivo.nombre}}</h2>
                                    <p class="mb-2">{{ejecutivo.cargo}}</p>  
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        <app-rating [ratingsCount]="5" [ratingsValue]="500"></app-rating>
                                    </div> 
                                    <span style="font-weight: bold;">{{ejecutivo.facebook}}</span>
                                </mat-card-content>
                                <mat-divider></mat-divider>
                                <mat-card-actions fxLayoutAlign="space-between center">
                                    <div fxLayout="row" fxLayoutAlign="start center" class="text-muted">
                                        <br>
                                        <!-- <span style="font-weight: bold;">Teléfono : {{ejecutivo.facebook}}</span>  -->
                                        <!-- <a href="{{ejecutivo.facebook}}" class="social-icon" target="_blank">
                                          <svg class="mat-icon" viewBox="0 0 24 24">
                                            <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
                                          </svg>
                                        </a>
                                        <a href="{{ejecutivo.twitter}}" class="social-icon" target="_blank">
                                          <svg class="mat-icon" viewBox="0 0 24 24">
                                              <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z" />
                                          </svg> 
                                        </a>
                                        <a href="{{ejecutivo.linkedin}}" class="social-icon" target="_blank"> 
                                          <svg class="mat-icon" viewBox="0 0 24 24">
                                            <path d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M18.5,18.5V13.2A3.26,3.26 0 0,0 15.24,9.94C14.39,9.94 13.4,10.46 12.92,11.24V10.13H10.13V18.5H12.92V13.57C12.92,12.8 13.54,12.17 14.31,12.17A1.4,1.4 0 0,1 15.71,13.57V18.5H18.5M6.88,8.56A1.68,1.68 0 0,0 8.56,6.88C8.56,5.95 7.81,5.19 6.88,5.19A1.69,1.69 0 0,0 5.19,6.88C5.19,7.81 5.95,8.56 6.88,8.56M8.27,18.5V10.13H5.5V18.5H8.27Z" />
                                          </svg>
                                        </a>
                                        <a href="{{ejecutivo.instagram}}" class="social-icon" target="_blank"> 
                                          <svg class="mat-icon" viewBox="0 0 24 24">
                                              <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                                          </svg>
                                        </a>  -->
                                    </div>
                                    <!-- <div>
                                        <a [routerLink]="['/chefs', chef.id]" mat-button color="primary" class="uppercase">View Profile</a>
                                    </div>  -->
                                </mat-card-actions>                              
                            </mat-card> 
                        </div>
                    </div>                      
                    <button mat-mini-fab color="primary" class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
                    <button mat-mini-fab color="primary" class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
                </div>
            </div> 
            <!-- <div class="w-100 text-center mt-5">
                <a routerLink="/chefs" mat-raised-button color="primary" class="uppercase">Our chefs</a>
            </div>   -->
        </div>
    </div>   
</div>  