import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(public appService:AppService) { }

  ngOnInit() {
  }

  public hayLogin():boolean{
    return this.appService.validarToken();
  }

  public logOut(){
    return this.appService.logOut();
  }

}
